export const newsletter = {
  abacus: {
    entityId: "327098",
    entityLabel: "ABACUS"
  },
  asia: {
    entityId: "323063",
    entityLabel: "THIS WEEK IN ASIA"
  },
  betterLife: {
    entityId: "502407",
    entityLabel: undefined
  },
  breakingNews: {
    entityId: "323473",
    entityLabel: "BREAKING NEWS ALERTS"
  },
  chinaAtAGlance: {
    entityId: "322279",
    entityLabel: "China at a Glance"
  },
  chinaBusinessBriefing: {
    entityId: "324680",
    entityLabel: undefined
  },
  coronavirus: {
    entityId: "505280",
    entityLabel: "CONFIRMED CORONAVIRUS CASES"
  },
  globalImpact: {
    entityId: "506194",
    entityLabel: "SCMP Global Impact Newsletter"
  },
  hkBusinessBriefing: {
    entityId: "324679",
    entityLabel: undefined
  },
  hongKongUpdate: {
    entityId: "510083",
    entityLabel: "Hong Kong Update"
  },
  inPictures: {
    entityId: "324669",
    entityLabel: undefined
  },
  insideChinaTech: {
    entityId: "329707",
    // cspell: ignore INSIDECHINATECH
    entityLabel: "INSIDECHINATECH"
  },
  kPop: {
    entityId: "507747",
    entityLabel: undefined
  },
  lifeAndCulture: {
    entityId: "322280",
    entityLabel: undefined
  },
  listeningPost: {
    entityId: "511120",
    entityLabel: undefined
  },
  longReads: {
    entityId: "324615",
    entityLabel: undefined
  },
  lunar: {
    entityId: "501957",
    entityLabel: undefined
  },
  marketsToday: {
    entityId: "500264",
    entityLabel: undefined
  },
  martialArts: {
    entityId: "502648",
    entityLabel: undefined
  },
  opinion: {
    entityId: "323850",
    entityLabel: undefined
  },
  posties: {
    entityId: "516369",
    entityLabel: "SCMP Parents"
  },
  postMag: {
    entityId: "322743",
    entityLabel: "POST MAGAZINE"
  },
  racing: {
    entityId: "323665",
    entityLabel: "HK RACING"
  },
  scmpProperty: {
    entityId: "322281",
    entityLabel: undefined
  },
  scmpTodayHk: {
    entityId: "20916",
    entityLabel: "SCMP TODAY: HK EDITION"
  },
  scmpTodayInt: {
    entityId: "322277",
    entityLabel: "SCMP TODAY: INTL EDITION"
  },
  style: {
    entityId: "322992",
    entityLabel: undefined
  },
  supportScmp1: {
    entityId: "506605",
    entityLabel: undefined
  },
  supportScmp2: {
    entityId: "506677",
    entityLabel: undefined
  },
  supportScmp3: {
    entityId: "506597",
    entityLabel: undefined
  },
  supportScmp4: {
    entityId: "506696",
    entityLabel: undefined
  },
  thisWeekInAsia: {
    entityId: "323063",
    entityLabel: undefined
  },
  usChinaTradeWar: {
    entityId: "330177",
    entityLabel: undefined
  }
};