import {
  ArticleThemeSignUpButtonDisplay,
  ArticleThemeTitle,
  NewsletterVariant,
  notEmpty,
} from "@product/scmp-sdk";
import type { NewsletterWidgetProps } from "@product/scmp-sdk";
import first from "lodash/first";
import uniqBy from "lodash/uniqBy";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { newsletter } from "shared/data";

import { massageToNewsletterItem } from "scmp-app/components/newsletter/helpers";
import { NewsletterWidgetContainer } from "scmp-app/components/newsletter/newsletter-widget-container";
import { useNewsletterWidget } from "scmp-app/components/newsletter/newsletter-widget-container/hooks";
import type { contentNewsletterWidgetContent$key } from "scmp-app/queries/__generated__/contentNewsletterWidgetContent.graphql";

type Props = {
  className?: string;
  reference: contentNewsletterWidgetContent$key;
  sequence?: ("article" | "section" | "topic")[];
} & Partial<NewsletterWidgetProps>;

export const ContentNewsletterWidget: FunctionComponent<Props> = ({
  reference: reference_,
  sequence = ["article", "topic", "section"],
  signUpButtonDisplayComponent = <ArticleThemeSignUpButtonDisplay />,
  titleComponent = <ArticleThemeTitle />,
  variant = NewsletterVariant.Article,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentNewsletterWidgetContent on Content {
        topics {
          relatedNewsletters {
            ...helpersMassageToNewsletterItemNewsletter
          }
        }
        sections {
          value {
            relatedNewsletters {
              ...helpersMassageToNewsletterItemNewsletter
            }
          }
        }
        ... on Article {
          relatedNewsletters {
            ...helpersMassageToNewsletterItemNewsletter
          }
        }
      }
    `,
    reference_,
  );

  const articleNewsletters =
    content?.relatedNewsletters
      ?.map(newsletter => massageToNewsletterItem(newsletter))
      .filter(notEmpty) ?? [];

  // Get the sub section newsletter by reverse the ordering first
  const sectionNewsletters =
    first(content?.sections)
      ?.value?.map(section => section)
      .reverse()
      .flatMap(section => section?.relatedNewsletters)
      .map(newsletter => massageToNewsletterItem(newsletter))
      .filter(notEmpty) ?? [];

  const topicsNewsletters =
    content?.topics
      ?.flatMap(topic => topic?.relatedNewsletters)
      .map(newsletter => massageToNewsletterItem(newsletter))
      .filter(notEmpty) ?? [];

  const newslettersMap = {
    article: articleNewsletters,
    section: sectionNewsletters,
    topic: topicsNewsletters,
  } as const;

  const newsletters = sequence.flatMap(newsletterType => newslettersMap[newsletterType]);

  // The sequence of the newsletters is
  // 1: Article Newsletters
  // 2: Topics Newsletters
  // 3: Subsection Newsletters
  // 4: Section Newsletters

  const mergedNewsletters = uniqBy(newsletters, newsletter => newsletter?.entityId);

  const { dataMatrix } = useNewsletterWidget({
    contentType: "article",
    eventCategory: "Newsletter (Article - Inline)",
    section: "",
  });

  return (
    <NewsletterWidgetContainer
      {...props}
      dataMatrix={dataMatrix}
      newsletterId={newsletter.globalImpact.entityId}
      newsletterItems={mergedNewsletters}
      queryParametersForSocialButtons={{}}
      signUpButtonDisplayComponent={signUpButtonDisplayComponent}
      titleComponent={titleComponent}
      variant={variant}
    />
  );
};

ContentNewsletterWidget.displayName = "ContentNewsletterWidget";
