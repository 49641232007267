import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { topic as topicData } from "shared/data";

import type { checkersCheckContentContainsStarlingImageContent$key } from "scmp-app/queries/__generated__/checkersCheckContentContainsStarlingImageContent.graphql";

export const checkContentContainsStarlingImage = (
  reference?: checkersCheckContentContainsStarlingImageContent$key | null,
) => {
  const content = readInlineData(
    graphql`
      fragment checkersCheckContentContainsStarlingImageContent on Content @inline {
        topics {
          entityId
        }
      }
    `,
    reference ?? null,
  );

  const starlingTopic = [
    topicData.hongKongCouncilElection2021.entityId,
    topicData.hongKongChiefExecutiveElection2022.entityId,
  ] as string[];

  return notEmpty(content?.topics?.find(topic => starlingTopic.includes(topic?.entityId ?? "")));
};
